@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000000;
    z-index:-1;
    overflow: hidden;
}

.background span {
    width: 50vmin;
    height: 50vmin;
    border-radius: 50vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 1;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #35422e;
    top: 74%;
    left: 74%;
    animation-duration: 159s;
    animation-delay: -314s;
    transform-origin: 4vw -18vh;
    box-shadow: 100vmin 0 12.877049438313765vmin currentColor;
}
.background span:nth-child(1) {
    color: #35422e;
    top: 90%;
    left: 79%;
    animation-duration: 306s;
    animation-delay: -235s;
    transform-origin: 10vw 2vh;
    box-shadow: 100vmin 0 12.786981879039018vmin currentColor;
}
.background span:nth-child(2) {
    color: #35422e;
    top: 55%;
    left: 58%;
    animation-duration: 481s;
    animation-delay: -97s;
    transform-origin: -13vw 1vh;
    box-shadow: 100vmin 0 13.160240538952898vmin currentColor;
}
.background span:nth-child(3) {
    color: #35422e;
    top: 65%;
    left: 65%;
    animation-duration: 450s;
    animation-delay: -328s;
    transform-origin: 11vw -18vh;
    box-shadow: -100vmin 0 13.277888932759419vmin currentColor;
}
.background span:nth-child(4) {
    color: #35422e;
    top: 89%;
    left: 11%;
    animation-duration: 204s;
    animation-delay: -20s;
    transform-origin: -21vw 18vh;
    box-shadow: -100vmin 0 13.446708935327417vmin currentColor;
}
.background span:nth-child(5) {
    color: #35422e;
    top: 70%;
    left: 97%;
    animation-duration: 170s;
    animation-delay: -279s;
    transform-origin: 14vw 20vh;
    box-shadow: -100vmin 0 13.013522226098232vmin currentColor;
}
.background span:nth-child(6) {
    color: #35422e;
    top: 95%;
    left: 52%;
    animation-duration: 225s;
    animation-delay: -97s;
    transform-origin: -13vw 0vh;
    box-shadow: -100vmin 0 12.822910915956104vmin currentColor;
}
.background span:nth-child(7) {
    color: #35422e;
    top: 84%;
    left: 22%;
    animation-duration: 101s;
    animation-delay: -460s;
    transform-origin: 24vw 18vh;
    box-shadow: 100vmin 0 13.389899706968755vmin currentColor;
}
.background span:nth-child(8) {
    color: #35422e;
    top: 57%;
    left: 35%;
    animation-duration: 321s;
    animation-delay: -241s;
    transform-origin: -8vw -23vh;
    box-shadow: 100vmin 0 13.349345387648166vmin currentColor;
}
.background span:nth-child(9) {
    color: #35422e;
    top: 74%;
    left: 42%;
    animation-duration: 381s;
    animation-delay: -388s;
    transform-origin: -9vw 24vh;
    box-shadow: -100vmin 0 12.68063659514352vmin currentColor;
}
