@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --background:224 71% 4%;
  --foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --ring: 215 20.2% 65.1%;
  --background: 224 71% 4%;
  --foreground: 213 31% 91%;
  --muted: 223 47% 11%;
  --muted-foreground: 215.4 16.3% 56.9%;
  --accent: 216 34% 17%;
  --accent-foreground: 210 40% 98%;
  --popover: 224 71% 4%;
  --popover-foreground: 215 20.2% 65.1%;
  --border: 216 34% 17%;
  --input: 216 34% 17%;
  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;
  --primary: 90 95% 70%;
  --primary-foreground: 240 10% 3.9%;
  --secondary: 222.2 47.4% 11.2%;
  --secondary-foreground: 210 40% 98%;
  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;
  --ring: 216 34% 17%;
  --radius: .5rem
}

body {
  color: #fff;
  background: hsl(var(--background));
}


.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem
}

@media (min-width: 1400px) {
  .container {
      max-width:1400px
  }
}

body{
  cursor:none;
}
html:not(.disable-cursor), html:not(.disable-cursor) *:not(.input):not(.textarea) {
  cursor: none !important;
  user-select: none;
  -webkit-user-select: none;
}
#invertedcursor {
  position: absolute;
  width: 40px;
  height: 40px;
  background: hsl(89.79deg 94.77% 70%);
  border-radius: 50%;
  top: var(--y, 0);
  left: var(--x, 0);
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none; /* ADD_ME */
  mix-blend-mode: difference;
  transition: transform .2s;
}

body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(155, 155, 155 ,0.2);
	-moz-box-shadow: inset 0 0 6px rgba(155, 155, 155 ,0.2);
	border-radius: 0;
	background-color: #141414
}

body::-webkit-scrollbar {
	width: 8px;
	background-color: #141414
}

body::-webkit-scrollbar-thumb {
	border-radius: 15px;
	background-color: hsl(89.79deg 94.77% 70%)
}
::selection{
  background-color: hsl(89.79deg 94.77% 70%);
  color:#000
}


.text-primary-foreground {
  color: hsl(var(--primary-foreground));
}
.bg-primary {
  background-color: hsl(var(--primary));
}
.border-foreground-10 {
  border-color: hsl(var(--foreground) / .1);
}

@media (min-width: 1400px){
  .container {
    max-width: 1400px;
    margin:0 auto;
  }
}

.headline-text{
  display:inline-block;
  vertical-align:top;
  text-decoration:inherit;
  text-wrap:balance
}

.marquee-container{
  --pause-on-hover: running; --pause-on-click: running; --width: 100%; --transform: none;
}
.marquee{
  --play: running; --direction: normal; --duration: 51.84s; --delay: 0s; --iteration-count: infinite; --min-width: 100%;
}
.marquee-container .child{
  --transform: none;
}
.initial-child-container {
  flex: 0 0 auto;
  display: flex;
  min-width: auto;
  flex-direction: row;
}
.hover\:bg-primary:hover {
  background-color: hsl(var(--primary));
}
.hover\:text-primary-foreground:hover {
  color: hsl(var(--primary-foreground));
}



